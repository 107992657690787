<template>
  <v-main>
    <homepage-carousel/>
    <homepage-product/>
    <homepage-security/>
    <homepage-about/>
    <homepage-partner/>
  </v-main>
</template>

<script>
import HomepageCarousel from '@/components/HomepageComponents/HomepageCarousel'
import HomepageAbout from "@/components/HomepageComponents/HomepageAbout";
import HomepageProduct from "@/components/HomepageComponents/HomepageProduct";
import HomepageSecurity from "@/components/HomepageComponents/HomepageSecurity";
import HomepagePartner from "@/components/HomepageComponents/HomepagePartner";

export default {
  name: 'Homepage',
  props: {
    is_small: Boolean
  },
  components: {
    HomepagePartner,
    HomepageSecurity,
    HomepageProduct,
    HomepageAbout,
    HomepageCarousel,
  },
}
</script>

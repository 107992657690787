<template>
  <v-sheet class="px-xl-16 px-lg-16 px-md-16 px-sm-16 px-4 py-xl-16 py-lg-16 py-md-16 py-sm-16 py-16">
    <v-row class="mx-xl-16 mx-lg-16 mx-md-0 mx-sm-0 mx-0" justify="center">
      <v-col xl="3" lg="3" md="6" sm="6" cols="12" v-for="(product, i) in products" :key="i">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card class="pa-2 rounded-lg" :elevation="hover ? 12 : 0" outlined height="100%" :to="product.to">
              <v-layout justify-center align-center>
                <v-img :src="product.img_src" contain max-width="200px"></v-img>
              </v-layout>
              <v-card-title class="justify-center text-center">{{ product.title }}</v-card-title>
              <v-card-text class="text-center">{{ product.introduction }}</v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "ProductProduct",

  data: () => ({
    products: [
      {
        img_src: require('@/assets/homepage_products_img/homepage_product_img_1.png'),
        title: "通信网络安全防护定级备案",
        introduction: "指导企业按照《网络安全法》、《通信网络安全安全防护管理办法》、《加强工业互联网安全工作的指导意见》等有关法律法规和文件要求，测算网络单元安全等级，并产出符合审查要求的定级报告、符合性测评报告、风险评估及整改报告。",
        to: "/product/01"
      }, {
        img_src: require('@/assets/homepage_products_img/homepage_product_img_2.png'),
        title: "APP安全与合规",
        introduction: "基于安全技术及工具提供APP安全检测、渗透测试、漏洞扫描、恶意代码检测及安全加固等，同时提供APP安全认证服务",
        to: "/product/02"
      }, {
        img_src: require('@/assets/homepage_products_img/homepage_product_img_3.png'),
        title: "数据治理与数据安全",
        introduction: "基于行业法律法规及领域专家，以数据识别与分类分级为基础，提供涵盖数据全生命周期的安全治理、风险识别与风险评估服务",
        to: "/product/03"
      }, {
        img_src: require('@/assets/homepage_products_img/homepage_product_img_4.png'),
        title: "网络安全等级保护",
        introduction: "指导企业遵循公安部《信息系统安全等级保护定级指南》，根据企业业务的特性和对信息系统的依赖程度，分别确定“业务信息安全保护等级”和“系统服务安全保护等级”，最终确定安全等级。",
        to: "/product/04"
      }, {
        img_src: require('@/assets/homepage_products_img/homepage_product_img_5.png'),
        title: "重大活动安全保障服务",
        introduction: "面向重大活动安全保障(如护网等)提供的防护服务，包含前期的事前准备、安全整改与攻防预演，中期的正式护网以及后期的总结汇报。",
        to: "/product/05"
      }, {
        img_src: require('@/assets/homepage_products_img/homepage_product_img_6.png'),
        title: "算法备案",
        introduction: "为客户完成算法评估和分析、备案材料准备、备案申请提交、备案审核跟进等算法备案全生命周期提供服务",
        to: "/product/06"
      }, {
        img_src: require('@/assets/homepage_products_img/homepage_product_img_7.png'),
        title: "安全产品与技术服务",
        introduction: "网站安全防护平台运用RBI（远程浏览器隔离）技术对网站进行转码，并通过私有协议向访问者呈现基于对象的视觉镜像，该私有协议只接受访问者在网站界面上的“键盘和鼠标操作”，同时兼具URL加密等功能，从原理上杜绝了绝大多数WEB攻击。",
        to: "/product/07"
      }, {
        img_src: require('@/assets/homepage_products_img/homepage_product_img_8.png'),
        title: "合规咨询",
        introduction: "合规体系建设、争议解决及事件应对、产品合规评审、专项法律意见、日常法律咨询、手续流程支持等。",
        to: "/product/08"
      },
    ]
  }),
}
</script>

<style scoped>

</style>

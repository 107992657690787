<template>
  <v-main>
    <v-row class="pa-16">
      <v-col cols="12" class="text-center">
        <div class="text-h3">无法找到该页面</div>
      </v-col>
      <v-col cols="12" class="text-center">
        <div class="text-h4">错误代码：404</div>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn plain to="/" class="text-body-1">请点击此处返回首页</v-btn>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>

</style>

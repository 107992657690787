<template>
  <v-sheet class="px-xl-16 px-lg-16 px-md-16 px-sm-16 px-4 py-xl-16 py-lg-16 py-md-16 py-sm-16 py-16">
    <v-row class="mx-xl-16 mx-lg-16 mx-md-0 mx-sm-0 mx-0" justify="center">
      <v-col xl="3" lg="3" md="4" sm="6" cols="12" v-for="(card, i) in cards" :key="i">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card class="pa-2 rounded-lg" height="100%" outlined :elevation="hover ? 12 : 0">
              <v-card-title>{{ card.title }}</v-card-title>
              <v-sheet min-height="120px">
                <v-card-text>{{ card.text }}</v-card-text>
              </v-sheet>
              <v-card-actions>
                <v-btn color="primary" :to="card.to">{{ card.btn }}</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "AboutList",
  data: () => ({
    cards: [
      {
        title: "公司介绍",
        text: "更多关于上海映涵信息科技有限公司的信息。",
        btn: "查看更多介绍",
        to: "/about/about_company_introduction"
      },
      {
        title: "荣誉资质",
        text: "上海映涵信息科技有限公司运营至今获得的荣誉和资质。",
        btn: "查看全部证书",
        to: "/about/about_certification"
      },
      {
        title: "加入我们",
        text: "聪明的你在哪？我们需要你，快来加入我们！",
        btn: "查看招聘信息",
        to: "/about/about_join_us"
      },
      {
        title: "联系我们",
        text: "遇到问题了？马上联系我们，我们将竭诚为您服务。",
        btn: "查看联系方式",
        to: "/about/about_contact_us"
      },
    ]
  })
}
</script>

<style scoped>

</style>

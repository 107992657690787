import { render, staticRenderFns } from "./DisplayNovel.vue?vue&type=template&id=84ce1a8e&scoped=true&"
import script from "./DisplayNovel.vue?vue&type=script&lang=js&"
export * from "./DisplayNovel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84ce1a8e",
  null
  
)

export default component.exports
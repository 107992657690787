<template>
  <v-main>
    <banner-image :img_src="banner.img_src" :title="banner.title" :description="banner.description"/>
    <product-product/>
  </v-main>
</template>

<script>
import BannerImage from "@/components/BannerImage";
import ProductProduct from "@/components/ProductProduct";

export default {
  name: "Product",
  components: {ProductProduct, BannerImage},
  data: () => ({
    banner: {
      img_src: require('@/assets/homepage_about_parallax.png'),
      title: "产品与服务",
      description: "网络安全问题，我们对症下药"
    }
  })
}
</script>

<style scoped>

</style>

<template>
  <v-carousel :height="carousel_height" hide-delimiter-background hide-delimiters show-arrows-on-hover cycle  style="padding-left: 12%;padding-right: 12%">
    <v-carousel-item v-for="(carousel, i) in carousels" :key="i" :src="carousel.img_src">
      <v-row class="fill-height" align="center" justify="center">
        <v-col xl="10" lg="10" md="10" sm="11" cols="11">
          <div class="text-xl-h2 text-lg-h2 text-md-h2 text-sm-h4 text-h5 mb-xl-4 mb-lg-4 mb-md-4 mb-sm-2 mb-0">
            {{ carousel.title }}
          </div>
          <div class="text-xl-h4 text-lg-h4 text-md-h4 text-sm-h6 text-subtitle-2 mb-xl-4 mb-lg-4 mb-md-4 mb-sm-2 mb-2">
            {{ carousel.subtitle }}
          </div>
          <v-btn v-if="carousel.btn" color="primary" :to="carousel.to" :small="is_small">{{ carousel.btn }}</v-btn>
        </v-col>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'HomepageCarousel',

  data: () => ({
    carousels: [
      {
        img_src: require('@/assets/homepage_carousel_img/homepage_carousel_img_4.png'),
        title: "映涵科技",
        subtitle: "网络安全和数据安全一体化解决方案提供商",
        btn: "关于我们",
        to: "/about"
      },
      {
        img_src: require('@/assets/homepage_carousel_img/homepage_carousel_img_1.png'),
        title: "网络安全产品与服务",
        subtitle: "网络安全问题，我们对症下药",
        btn: "查看产品与服务",
        to: "/product"
      },
      {
        img_src: require('@/assets/homepage_carousel_img/homepage_carousel_img_2.png'),
        title: "映涵合作伙伴",
        subtitle: "他们都信赖映涵，携手共建安全生活",
        btn: "查看合作伙伴",
        to: "/partner"
      },
      {
        img_src: require('@/assets/homepage_carousel_img/homepage_carousel_img_3.png'),
        title: "网络安全动态",
        subtitle: "技术干货、行业时讯、专家解读",
        btn: "查看安全动态",
        to: "/security"
      }
    ],
  }),
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    carousel_height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '30vh'
        case 'sm':
          return '30vh'
        case 'md':
          return '65vh'
        case 'lg':
          return '65vh'
        case 'xl':
          return '65vh'
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    is_small() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        case 'sm':
          return true
        case 'md':
          return false
        case 'lg':
          return false
        case 'xl':
          return false
      }
    }
  }
}
</script>

<template>
  <v-app>
    <app-bar/>
    <router-view/>
    <app-foot-info/>
  </v-app>
</template>

<script>

import AppBar from "@/components/AppBar";
import AppFootInfo from "@/components/AppFootInfo";

export default {
  name: 'App',
  components: {AppBar, AppFootInfo},
  data: () => ({
    //
  })
};
</script>

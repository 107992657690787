<template>
  <v-parallax dark src="@/assets/homepage_about_parallax.png">
    <v-overlay :absolute="true" :value="true">
      <v-row>
        <v-col xl="5" offset-xl="6" lg="5" offset-lg="6" md="5" offset-md="6" sm="8" offset-sm="3" cols="10" offset="1">
          <div class="text-xl-h2 text-lg-h2 text-md-h2 text-sm-h4 text-h4 mb-xl-4 mb-lg-4 mb-md-4 mb-sm-2 mb-2">企业优势</div>
          <div class="text-body-1 mb-xl-4 mb-lg-4 mb-md-4 mb-sm-2 mb-2">上海映涵信息科技有限公司是专业的信息安全测评和咨询机构。</div>
          <div class="text-body-1 mb-xl-4 mb-lg-4 mb-md-4 mb-sm-2 mb-2">公司是上海市通信管理局支撑单位，负责APP个人隐私保护检测和检测标准的制定，同时是上海市通信学会通信安全技术专业委员会专家组成员，参与通信网络单元定级。
          </div>
          <div class="text-body-1 mb-xl-4 mb-lg-4 mb-md-4 mb-sm-2 mb-2">公司已和国内多家头部安全厂商建立战略合作伙伴关系，实现信息和资源的互通、共享。</div>
          <div class="text-body-1 mb-xl-4 mb-lg-4 mb-md-4 mb-sm-2 mb-2">提供专业线上自动化检测和线下个性化的解决方案。</div>
        </v-col>
      </v-row>
    </v-overlay>
  </v-parallax>
</template>

<script>
export default {
  name: "HomepageAbout"
}
</script>

<style scoped>

</style>
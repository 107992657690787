<template>
  <v-sheet
      class="px-xl-16 px-lg-16 px-md-16 px-sm-16 px-4 pt-xl-8 pt-lg-8 pt-md-8 pt-sm-8 pt-8 pb-xl-16 pb-lg-16 pb-md-16 pb-sm-16 pb-16">
    <v-row class="mx-xl-16 mx-lg-16 mx-md-0 mx-sm-0 mx-0" justify="center" dense v-for="(col, i) in novel" :key="i">
      <v-col cols="10" v-if="col.title">
        <div class="text-xl-h4 text-lg-h4 text-md-h4 text-sm-h6 text-h6 text-center">{{ col.title }}</div>
      </v-col>
      <v-col cols="10" v-if="col.author">
        <div class="text-subtitle-1 text-center">{{ col.author }}</div>
      </v-col>
      <v-col cols="10" v-if="col.subtitle">
        <div class="text-h6">{{ col.subtitle }}</div>
      </v-col>
      <v-col cols="10" v-if="col.words">
        <div class="text-body-1">{{ col.words }}</div>
      </v-col>
      <v-col cols="10" v-if="col.indentedWords">
        <div class="text-body-1 pl-6">{{ col.indentedWords }}</div>
      </v-col>
      <v-col cols="10" v-if="col.indentedIndentedWords">
        <div class="text-body-1 pl-12">{{ col.indentedIndentedWords }}</div>
      </v-col>
      <v-col xl="8" lg="8" md="8" sm="10" cols="10" v-if="col.img">
        <v-img :src="col.img" width="100%"/>
      </v-col>
      <v-col cols="10" v-if="col.sign">
        <div class="text-body-1 text-right">{{ col.sign }}</div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "DisplayNovel",
  props: {
    novel: Array
  }
}
</script>

<style scoped>

</style>
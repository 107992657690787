<template>
  <v-main>
    <banner-image :img_src="banner.img_src" :title="banner.title" :subtitle="banner.subtitle"/>
    <display-novel :novel="novel"/>
  </v-main>
</template>

<script>
import BannerImage from "@/components/BannerImage";
import DisplayNovel from "@/components/DisplayNovel";

export default {
  name: "ProductInfo",
  components: {
    DisplayNovel,
    BannerImage
  },
  data: () => ({
    banner: {
      img_src: require('@/assets/homepage_about_parallax.png'),
      title: "产品与服务",
      subtitle: ""
    },
    novel: []
  }),
  watch: {
    '$route'() {
      this.$router.go(0);
    }
  },
  created() {
    switch (this.$route.params.product_info_id) {
      case "01": {
        this.banner.subtitle = "通信网络安全防护定级备案"
        this.banner.img_src = require('@/assets/product_info_img/product_info_img_1.png')
        this.novel = [
          {img: require('@/assets/product_info_img/01/01.png')},
          {img: require('@/assets/product_info_img/01/02.png')}
        ]
        break
      }
      case "02": {
        this.banner.subtitle = "APP安全与合规"
        this.banner.img_src = require('@/assets/product_info_img/product_info_img_2.png')
        this.novel = [
          {img: require('@/assets/product_info_img/02/01.png')},
          {img: require('@/assets/product_info_img/02/02.png')},
          {img: require('@/assets/product_info_img/02/03.png')},
          {img: require('@/assets/product_info_img/02/04.png')},
          {img: require('@/assets/product_info_img/02/05.png')},
          {img: require('@/assets/product_info_img/02/06.png')},
          {img: require('@/assets/product_info_img/02/07.png')},
        ]
        break
      }
      case "03": {
        this.banner.subtitle = "数据治理与数据安全"
        this.banner.img_src = require('@/assets/product_info_img/product_info_img_3.png')
        this.novel = [
        {img: require('@/assets/product_info_img/03/01.png')},
          {img: require('@/assets/product_info_img/03/02.png')},
          {img: require('@/assets/product_info_img/03/03.png')},
          {img: require('@/assets/product_info_img/03/04.png')},
          {img: require('@/assets/product_info_img/03/05.png')},
        ]
        break
      }
      case "04": {
        this.banner.subtitle = "网络安全等级保护"
        this.banner.img_src = require('@/assets/product_info_img/product_info_img_4.png')
        this.novel = [
        {img: require('@/assets/product_info_img/04/01.png')},
        ]
        break
      }
      case "05": {
        this.banner.subtitle = "重大活动安全保障服务"
        this.banner.img_src = require('@/assets/product_info_img/product_info_img_5.png')
        this.novel = [
        {img: require('@/assets/product_info_img/05/01.png')},
          {img: require('@/assets/product_info_img/05/02.png')},
          {img: require('@/assets/product_info_img/05/03.png')},
        ]
        break
      }
      case "06": {
        this.banner.subtitle = "算法备案"
        this.banner.img_src = require('@/assets/product_info_img/product_info_img_6.png')
        this.novel = [
        {img: require('@/assets/product_info_img/06/01.png')},
        ]
        break
      }
      case "07": {
        this.banner.subtitle = "安全产品与技术服务"
        this.banner.img_src = require('@/assets/product_info_img/product_info_img_7.png')
        this.novel = [
        {img: require('@/assets/product_info_img/07/01.png')},
          {img: require('@/assets/product_info_img/07/02.png')},
          {img: require('@/assets/product_info_img/07/03.png')},
          {img: require('@/assets/product_info_img/07/04.png')},
        ]
        break
      }
      case "08": {
        this.banner.subtitle = "合规咨询"
        this.banner.img_src = require('@/assets/product_info_img/product_info_img_8.png')
        this.novel = [
          {img: require('@/assets/product_info_img/08/01.png')},
          {img: require('@/assets/product_info_img/08/02.png')}
        ]
        break
      }
    }
  }
}
</script>

<style scoped>

</style>

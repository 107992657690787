<template>
  <v-main>
    <banner-image :img_src="banner.img_src" :title="banner.title" :subtitle="banner.subtitle"/>
    <about-join-us-show-join-us/>
  </v-main>
</template>

<script>
import BannerImage from "@/components/BannerImage";
import AboutJoinUsShowJoinUs from "@/components/AboutComponents/AboutJoinUsShowJoinUs";

export default {
  name: "AboutJoinUs",
  components: {AboutJoinUsShowJoinUs, BannerImage},
  data: () => ({
    banner: {
      img_src: require('@/assets/homepage_about_parallax.png'),
      title: "关于我们",
      subtitle: "加入我们"
    }
  })
}
</script>

<style scoped>

</style>
<template>
  <v-footer dark color="rgb(48,50,56)" class="pa-8">
    <v-row justify="center">
      <v-menu
        dark
        v-for="(foot_info, i) in foot_infos"
        :key="i"
        transition="scroll-y-reverse-transition"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn plain v-bind="attrs" v-on="on">{{ foot_info.title }}</v-btn>
        </template>

        <v-list color="rgb(48,50,56)">
          <v-list-item
            v-for="(list_info, i) in foot_info.list"
            :key="i"
            :to="list_info.path ? list_info.path : null"
          >
            <v-img
              :src="list_info.img_src"
              width="200px"
              v-if="list_info.img_src"
            />
            <v-list-item-title class="text-caption" v-if="list_info.title">{{
              list_info.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-col cols="12">
        <div class="text-subtitle-2 text-center">
          版权所有：上海映涵信息科技有限公司
        </div>
        <div class="text-subtitle-2 text-center">
          Copyright © 2021-2023 Wy. All rights reserved.
        </div>
        <div class="text-center">
          <v-btn
            class="text-subtitle-2"
            text
            x-small
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019457"
          >
            <v-img
              :src="require('@/assets/record_logo.png')"
              height="14"
              contain
            />
            沪公网安备31011502019457号
          </v-btn>
        </div>
        <div class="text-subtitle-2 text-center">沪ICP备2022033885号</div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "HomepageFootInfo",
  data: () => ({
    foot_infos: [
      {
        title: "热门产品",
        list: [
          { title: "通信网络安全防护定级备案", path: "/product/01" },
          { title: "App安全与合规", path: "/product/02" },
          { title: "数据治理与数据安全", path: "/product/03" },
        ],
      },
      {
        title: "关于我们",
        list: [
          { title: "公司介绍", path: "/about/about_company_introduction" },
          { title: "荣誉资质", path: "/about/about_certification" },
          { title: "加入我们", path: "/about/about_join_us" },
          { title: "联系我们", path: "/about/about_contact_us" },
        ],
      },
      {
        title: "联系我们",
        list: [
          { title: "客服热线：021-31185093" },
          { title: "电子邮箱：hezuo@inhamsh.cn" },
          { title: "在线客服（QQ）：2414415828" },
          { title: "更多联系方式", path: "/about/about_contact_us" },
        ],
      },
      {
        title: "关注我们",
        list: [{ img_src: require("@/assets/wechat_info.jpeg") }],
      },
    ],
  }),
};
</script>

<style scoped>
</style>

<template>
  <v-sheet class="px-xl-16 px-lg-16 px-md-16 px-sm-16 px-4 py-xl-16 py-lg-16 py-md-16 py-sm-16 py-16">
    <v-row dense>
      <v-col cols="12">
        <div class="text-xl-h2 text-lg-h2 text-md-h2 text-sm-h4 text-h4 text-center">安全动态</div>
      </v-col>
      <v-col cols="12">
        <div class="text-subtitle-1 text-center">技术干货、行业时讯、专家解读</div>
      </v-col>
    </v-row>

    <v-row class="mx-0" justify="center" v-if="$vuetify.breakpoint.name === 'xs'">
      <v-col cols="12" v-for="(security, i) in securities.slice(0, 4)" :key="i">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card class="rounded-lg" :elevation="hover ? 12 : 0" outlined @click="onclick(security.link)">
              <v-img :src="security.cover" height="200px"></v-img>
              <v-card-title>{{ security.title }}</v-card-title>
              <v-card-text class="my-n4">{{ security.publish_time }}</v-card-text>
              <v-card-text>{{ security.digest }}</v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>

    <v-row class="mx-0" justify="center" v-else-if="$vuetify.breakpoint.name === 'sm'">
      <v-col cols="6" v-for="(security, i) in securities.slice(0, 6)" :key="i">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card class="rounded-lg" :elevation="hover ? 12 : 0" outlined height="100%" @click="onclick(security.link)">
              <v-img :src="security.cover" height="200px"></v-img>
              <v-card-title>{{ security.title }}</v-card-title>
              <v-card-text class="my-n4">{{ security.date }}</v-card-text>
              <v-card-text>{{ security.digest }}</v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>

    <v-row align="stretch" justify="center" class="mx-xl-8 mx-lg-8 mx-md-0" v-else>
      <v-col xl="3" lg="5" md="5"
             v-for="(security, i) in securities.slice(page * page_length, (page + 1) * page_length)" :key="i">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card class="rounded-lg" :elevation="hover ? 12 : 0" outlined height="100%" @click="onclick(security.link)">
              <v-img :src="security.cover" height="200px" contain></v-img>
              <v-card-title>{{ security.title }}</v-card-title>
              <v-card-text class="my-n4">{{ security.publish_time }}</v-card-text>
              <v-card-text>{{ security.digest }}</v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>


    <v-row justify="center">
      <v-col cols="10" class="text-center">
        <v-btn text plain @click="pageDown" v-if="!is_small">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn color="primary" to="/security" :small="is_small">查看更多安全动态</v-btn>
        <v-btn text plain @click="pageUp" v-if="!is_small">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import axios from 'axios'
export default {
  name: "HomepageSecurity",
  components:{
    axios
  },
  data: () => ({
    page: 0,
    securities: [
              {
                title:"要点详解丨简析《工业和信息化领域数据安全风险评估实施细则（试行）》",
                publish_time:"2024-06-03 19:30",
                digest:"该细则明确了数据安全风险评估的适用对象、评估内容、评估频次、评估报告的提交与管理等关键环节，为工业和信息化领域的数据安全风险评估提供了具体的操作指南，标志着数据安全风险评估制度正式启动。",
                cover:"https://mmbiz.qpic.cn/mmbiz_jpg/IYo39KpXkibxiccITTzVawWrccsBDqDz2KN9ta4T7eMvYJYMJfIfltAtmqBmBgIwN2YIMFjlyq7SOicLLLibhuxWgw/640?wx_fmt=jpeg&amp;from=appmsg",
                link:"https://mp.weixin.qq.com/s?__biz=MzkyNzIzNzQwMw==&mid=2247490488&idx=1&sn=8a5993178371791fc71b4af29fcaecc4&chksm=c22a4fccf55dc6da6a42d0cce17f8f8112202f2365ebf731e45dbaa264dd888d24b36b13ada6&token=1089014281&lang=zh_CN#rd"
              },
              {
                title:"一文读懂丨大模型备案要点详解",
                publish_time:"2024-05-28 19:30",
                digest:"随着人工智能技术的发展，大模型在语音识别、图像处理、自然语言处理等领域应用日益广泛，为进一步保障和监管大模型技术应用，我国出台了《生成式人工智能服务管理暂行办法》，为大模型的合规提供了明确的法律框架。",
                cover:"https://mmbiz.qpic.cn/mmbiz_jpg/IYo39KpXkibyIUndnjNqLbHnqkicHkbXgTibDBRvMhNAqoJzXXcQACh5AElIOEes0O8ENr29pgS4oWWbWKoXlicW6Q/640?wx_fmt=jpeg&amp;from=appmsg",
                link:"https://mp.weixin.qq.com/s?__biz=MzkyNzIzNzQwMw==&mid=2247490432&idx=1&sn=c5b3fe23480b73af6561a27e72de0724&chksm=c22a4ff4f55dc6e2d54d3a4e1e10784f4eb24a3cd1f48feaf17f80cd862033a3ef8e2f20f0da&token=1089014281&lang=zh_CN#rd"
              },
              {
                title:"一文看懂丨算法备案要点全覆盖（附材料模板）",
                publish_time:"2024-05-23 19:30",
                digest:"算法备案，全称互联网信息服务算法备案，是由网信办主导的一种备案制度。随着人工智能、大数据等技术的发展，算法广泛应用于互联网信息服务，但也带来了隐私泄露、诱导沉迷等一系列问题。为确保算法应用的公平公正、透明可释，网信办联合多部门发布《互联网信息服务算法推荐管理规定》，自2022年3月起正式施行。",
                cover:"https://mmbiz.qpic.cn/mmbiz_jpg/IYo39KpXkiby06lLlRu4ibCK7wbS7eO0LgPBQpUtUDiaEjcC227cXjicbSMqqNMTMrJJ1kNS9efYjDCPVzAArntEBg/640?wx_fmt=jpeg&amp;from=appmsg",
                link:"https://mp.weixin.qq.com/s?__biz=MzkyNzIzNzQwMw==&mid=2247490359&idx=1&sn=fcb85b98803fe7bdbc30dc872d01075d&chksm=c22a4f43f55dc655c5e8fd20cf558807e9e9fa4cea27c7f669b0fc70540167c4b27a2f43119b&token=1089014281&lang=zh_CN#rd"
              },
              {
                title:"映涵安全丨APP合规安全上海行活动成功举办",
                publish_time:"2024-05-08 19:30",
                digest:"2024年5月7日下午，由上海市互联网协会主办、映涵安全承办的“5月APP合规安全上海行”活动在上海市静安区圆满落幕，超过100名代表参与了本次活动。映涵在活动上就移动互联网应用程序用户权益保护自评估工作做了专题演讲。",
                cover:"https://mmbiz.qpic.cn/mmbiz_jpg/IYo39KpXkibzQAry1Buutd60Dxbq1gOfMYbodb5R2nHuhFkqueaffx4ibHGRTqVjUOgTrr9Fcvk8CE3BUvuABEiaA/640?wx_fmt=jpeg&from=appmsg",
                link:"https://mp.weixin.qq.com/s?__biz=MzkyNzIzNzQwMw==&amp;mid=2247490264&amp;idx=1&amp;sn=5e604284120f1f44962b3b71fbe67868&amp;chksm=c22a4eacf55dc7baf3ff2bf8efae616873932350be3c40dfaa5a183f7fd54edcc72fc2590718&token=1089014281&lang=zh_CN&poc_token=HC1OamajSFblzd29Qeyu8JJcq-qru6S9j3KVXbrW"
              },
              {
                title:"如何做好APP用户权益保护自评估及常见问题梳理（附报告模板）",
                publish_time:"2024-04-28 19:00",
                digest:"根据《上海市移动互联网应用程序用户权益保护自评估规范指南》要求，APP服务提供者应开展APP用户权益保护自查评估工作。本文对如何做好APP用户权益保护自评估及常见问题进行了梳理。",
                cover:"https://mmbiz.qpic.cn/mmbiz_jpg/IYo39KpXkibw8StlJzgsxibdCN9m1C9jLHD1dTULMpJ4LlkFVy7BMk97JbaDJd69ObMHyMq6H3wKc164hkWleZ0g/640?wx_fmt=jpeg&from=appmsg",
                link:"https://mp.weixin.qq.com/s?__biz=MzkyNzIzNzQwMw==&amp;mid=2247490213&amp;idx=1&amp;sn=90b5d5caf1a183c3ccb2d545f53992f1&amp;chksm=c22a4ed1f55dc7c7634f2373719e6b73b928ce39f2e8518403b2f9677c70656d25d1a9bc700f&token=1089014281&lang=zh_CN#rd"
              }
            ]
  }),
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    is_small() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        case 'sm':
          return true
        case 'md':
          return false
        case 'lg':
          return false
        case 'xl':
          return false
      }
    },// eslint-disable-next-line vue/return-in-computed-property
    page_length() {
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          return 2
        case 'lg':
          return 2
        case 'xl':
          return 3
      }
    },
  },
  mounted(){
      axios.get('https://manager.inham.cn/api/website/article/display').then((resp)=>{
          if(resp.data.respCode==="200"){
            this.securities = resp.data.respBody
          }else{
            this.onError()
          }
      }).catch((error)=>{
        this.onError()
        console.log(error)
      })
    },
  methods: {
    pageUp: function () {
      if (this.page >= this.securities.length / this.page_length - 1) {
        this.page = 0
      } else {
        this.page++
      }
    },
    pageDown: function () {
      if (this.page <= 0) {
        this.page = Math.floor((this.securities.length - this.page_length + 1) / this.page_length)
      } else {
        this.page--
      }
    },
    onclick: function(url){
      window.open(url,'_blank')
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <v-main>
    <banner-image :img_src="banner.img_src" :title="banner.title" :description="banner.description"/>
    <purchase-approach/>
  </v-main>
</template>

<script>
import PurchaseApproach from "@/components/PurchaseApproach";
import BannerImage from "@/components/BannerImage";

export default {
  name: "Purchase",
  components: {BannerImage, PurchaseApproach},
  data: () => ({
    banner: {
      img_src: require('@/assets/homepage_about_parallax.png'),
      title: "如何购买",
      description: "映涵科技售前客服代表与资深销售顾问联手为您提供专业的购买咨询服务。"
    }
  })
}
</script>

<style scoped>

</style>

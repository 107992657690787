<template>
  <v-main>
    <banner-image :img_src="banner.img_src" :title="banner.title" :description="banner.description"/>
    <about-list/>
  </v-main>
</template>
<script>
import BannerImage from "@/components/BannerImage";
import AboutList from "@/components/AboutComponents/AboutList";

export default {
  components: {AboutList, BannerImage},
  data: () => ({
    banner: {
      img_src: require('@/assets/homepage_about_parallax.png'),
      title: "关于我们",
      description: "通过革新性安全方案和7天24小时全天候的专业服务保护，打造和谐、强大、高度安全的互联网生态环境。"
    },
  })
}
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from "@/views/Homepage";
import Product from "@/views/ProductViews/Product";
import ProductInfo from "@/views/ProductViews/ProductInfo";
import Purchase from "@/views/Purchase";
import Partner from "@/views/Partner";
import Security from "@/views/SecurityViews/Security";
import About from "@/views/AboutViews/About";
import AboutCompanyIntroduction from "@/views/AboutViews/AboutCompanyIntroduction";
import AboutCertification from "@/views/AboutViews/AboutCertification";
import AboutJoinUs from "@/views/AboutViews/AboutJoinUs";
import AboutContactUs from "@/views/AboutViews/AboutContactUs";
import PageNotFound from "@/views/PageNotFound";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Homepage',
        component: Homepage,
        meta: {
            title: "映涵科技｜主页"
        }
    },
    {
        path: '/product',
        name: 'Product',
        component: Product,
        meta: {
            title: "映涵科技｜服务与产品"
        }
    },
    {
        path: '/product/:product_info_id',
        name: 'ProductInfo',
        component: ProductInfo,
        meta: {
            title: "映涵科技｜服务与产品"
        }
    },
    {
        path: '/purchase',
        name: 'Purchase',
        component: Purchase,
        meta: {
            title: "映涵科技｜如何购买"
        }
    },
    {
        path: '/partner',
        name: 'Partner',
        component: Partner,
        meta: {
            title: "映涵科技｜合作伙伴"
        }
    },
    {
        path: '/security',
        name: 'Security',
        component: Security,
        meta: {
            title: "映涵科技｜安全动态"
        }
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: "映涵科技｜关于我们"
        }
    },
    {
        path: '/about/about_company_introduction',
        name: 'AboutCompanyIntroduction',
        component: AboutCompanyIntroduction,
        meta: {
            title: "映涵科技｜关于我们"
        }
    },
    {
        path: '/about/about_certification',
        name: 'AboutCertification',
        component: AboutCertification,
        meta: {
            title: "映涵科技｜关于我们"
        }
    },
    {
        path: '/about/about_join_us',
        name: 'AboutJoinUs',
        component: AboutJoinUs,
        meta: {
            title: "映涵科技｜关于我们"
        }
    },
    {
        path: '/about/about_contact_us',
        name: 'AboutContactUs',
        component: AboutContactUs,
        meta: {
            title: "映涵科技｜关于我们"
        }
    },
    {
        path: '*',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: {
            title: "映涵科技｜页面不存在"
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})

export default router

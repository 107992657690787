<template>
  <v-app-bar app color="white" elevate-on-scroll>
    <router-link to="/">
      <v-img contain :src="require('@/assets/logo.png')" max-height="40px" max-width="120px"/>
    </router-link>

    <v-spacer></v-spacer>

    <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
      <v-menu offset-y transition="scroll-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(menu, i) in menus" :key="i" :to="menu.path">
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div v-for="(menu,i) in menus" :key="i" v-else>
      <v-btn :to="menu.path" text style="font-size: large">{{ menu.title }}</v-btn>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
  data: () => ({
    show_menu: false,
    menus: [
      {
        title: "主页",
        path: "/"
      },
      {
        title: "产品与服务",
        path: "/product"
      },
      {
        title: "如何购买",
        path: "/purchase"
      },
      {
        title: "合作伙伴",
        path: "/partner"
      },
      {
        title: "安全动态",
        path: "/security"
      },
      {
        title: "关于我们",
        path: "/about"
      },
    ]
  })
}
</script>

<style scoped>

</style>
<template>
  <v-main>
    <banner-image :img_src="banner.img_src" :title="banner.title" :subtitle="banner.subtitle"/>
    <display-novel :novel="novel"/>
  </v-main>
</template>

<script>
import BannerImage from "@/components/BannerImage";
import DisplayNovel from "@/components/DisplayNovel";

export default {
  name: "AboutCompanyIntroduction",
  components: {DisplayNovel, BannerImage},
  data: () => ({
    banner: {
      img_src: require('@/assets/homepage_about_parallax.png'),
      title: "关于我们",
      subtitle: "公司介绍"
    },
    novel: [
      {words: "上海映涵信息科技有限公司是专业的信息安全测评和咨询机构。 公司是上海市通信管理局支撑单位，负责APP个人隐私保护检测和检测标准的制定，同时是上海市通信学会通信安全技术专业委员会专家组成员，参与通信网络单元定级。公司已和国内多家头部安全厂商建立战略合作伙伴关系，实现信息和资源的互通、共享。 提供专业线上自动化检测和线下个性化的解决方案。"},
      {words: "映涵获得了多项专业资质和荣誉，主导并参与多项国家标准和行业规范的制定，可提供满足合规要求的、基于企业移动信息安全的一体化综合解决方案。映涵拥有App安全测试、App渗透测试、App漏洞检测、App恶意代码检测等产品和服务，可为用户提供基于企业移动信息安全的一体化综合解决方案。这些解决方案贯穿了应用设计评估、安全开发测试、应用优化、应用安全发布及应用上线运营阶段的整个生命周期。"},
      {words: "目前，映涵行业用户遍及银行、证券、保险等金融行业（手机银行、移动支付、直销银行等）、运营商、政府、电商、能源、大中型企业、移动社交、移动办公、手机视频、手机游戏（强、弱联网游戏）等行业，覆盖亚洲和北美等主要市场。映涵现拥有近50万注册企业及开发者用户，为100多万款APP提供安全服务，累计覆盖9亿移动终端。"}
    ]
  })
}
</script>

<style scoped>

</style>
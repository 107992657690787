<template>
  <v-sheet class="px-xl-16 px-lg-16 px-md-16 px-sm-16 px-4 py-xl-16 py-lg-16 py-md-16 py-sm-16 py-16" >
    <v-row dense justify="center">
      <v-col cols="12">
        <div class="text-xl-h2 text-lg-h2 text-md-h2 text-sm-h4 text-h4 text-center">合作伙伴</div>
      </v-col>
      <v-col cols="12">
        <div class="text-subtitle-1 text-center">他们都信赖映涵，携手共建安全生活</div>
      </v-col>
    </v-row>
    <v-row class="mx-xl-16 mx-lg-16 mx-md-0 mx-sm-0 mx-0" justify="center" style="padding-left: 11%;padding-right: 11%;">
      <v-col xl="4" lg="4" md="4" sm="4" cols="6" v-for="(partner, i) in partners" :key="i" :href="partner.outter_link">
        <v-img class="ma-4 scale" :src="partner.img_src" :height="partner.height" contain />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "HomepagePartner",

  data: () => ({
    partners: [
      
      {
        img_src: require('@/assets/homepage_partner_logo/homepage_partner_logo_4.png'),
        name: "高合",
        height:"50px",
        outter_link: "https://m.hiphi.com"
      },
      {
        img_src: require('@/assets/homepage_partner_logo/homepage_partner_logo_5.png'),
        name: "阅文集团",
        height:"50px",
        outter_link: "https://www.yuewen.com"
      },
      {
        img_src: require('@/assets/homepage_partner_logo/homepage_partner_logo_6.jpg'),
        name: "帆书",
        height:"50px",
        outter_link: "https://www.fanshu.cn"
      },
      {
        img_src: require('@/assets/homepage_partner_logo/homepage_partner_logo_7.png'),
        name: "合合",
        height:"30px",
        outter_link: "https://www.intsig.com"
      },
      {
        img_src: require('@/assets/homepage_partner_logo/homepage_partner_logo_8.png'),
        name: "趣头条",
        height:"55px",
        outter_link: "https://www.qutoutiao.net"
      },
      {
        img_src: require('@/assets/homepage_partner_logo/homepage_partner_logo_9.png'),
        name: "网易游戏",
        height:"55px",
        outter_link: "https://game.163.com"
      },
      {
        img_src: require('@/assets/homepage_partner_logo/homepage_partner_logo_10.jpg'),
        name: "游族",
        height:"100px",
        outter_link: "https://www.youzu.com"
      },
      {
        img_src: require('@/assets/homepage_partner_logo/homepage_partner_logo_1.png'),
        name: "字节跳动",
        height:"75px",
        outter_link: "https://www.bytedance.com"
      },
      {
        img_src: require('@/assets/homepage_partner_logo/homepage_partner_logo_2.png'),
        name: "虎扑",
        height:"75px",
        outter_link: "https://www.hupu.com"
      },
      {
        img_src: require('@/assets/homepage_partner_logo/homepage_partner_logo_3.png'),
        name: "福特",
        height:"75px",
        outter_link: "https://www.ford.com.cn"
      },
      {
        img_src: require('@/assets/homepage_partner_logo/homepage_partner_logo_11.png'),
        name: "心动网络",
        height:"50px",
        outter_link: "https://www.xd.com"
      },
      {
        img_src: require('@/assets/homepage_partner_logo/homepage_partner_logo_12.png'),
        name: "波克",
        height:"50px",
        outter_link: "https://www.pook.com"
      },
    ]
  })
}
</script>

<style scoped>
/* .scale {
  height: 100%;
  width: auto;
} */
</style>

<template>
  <v-sheet
      class="px-xl-16 px-lg-16 px-md-16 px-sm-16 px-4 pt-xl-8 pt-lg-8 pt-md-8 pt-sm-8 pt-8 pb-xl-16 pb-lg-16 pb-md-16 pb-sm-16 pb-16">
    <v-row class="mx-xl-16 mx-lg-16 mx-md-0 mx-sm-0 mx-0" justify="center" dense>
      <v-col xl="5" lg="5" md="5" sm="5" cols="10">
        <div class="text-body-1">公司电话：021-31185093</div>
      </v-col>
      <v-col xl="5" lg="5" md="5" sm="5" cols="10" class="text-right">
        <v-btn plain href="tel:021-31185093">立即拨打电话</v-btn>
      </v-col>
      <v-col xl="5" lg="5" md="5" sm="5" cols="10">
        <div class="text-body-1">公司邮箱：hezuo@inhamsh.com</div>
      </v-col>
      <v-col xl="5" lg="5" md="5" sm="5" cols="10" class="text-right">
        <v-btn plain href="Mailto:hezuo@inhamsh.com">立即发送邮件</v-btn>
      </v-col>
      <v-col cols="10">
        <div class="text-body-1">公司地址：上海市普陀区曹杨路1888号星光耀广场</div>
      </v-col>
    </v-row>
    <v-row class="mx-xl-16 mx-lg-16 mx-md-0 mx-sm-0 mx-0" justify="center">
      <v-col cols="10">
        <baidu-map class="bm-view" :center="{lng: 121.413163, lat: 31.262994}" zoom="15" :ak="ak">
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
          <bm-marker :position="{lng: 121.413163, lat: 31.262994}" :dragging="true">
            <bm-label content="上海星光耀广场" :labelStyle="{color: 'red', fontSize : '12px'}"
                      :offset="{width: -30, height: -25}"/>
          </bm-marker>
        </baidu-map>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
export default {
  name: "AboutContactUsShowContactUs",
  components:{
    BaiduMap
  },
  data: () => ({
    return:{
      ak: '1iRZE3FMEpuhvvZkZvqjX2zGTAiGKvZ7'
    } 
  })
}
</script>

<style scoped>
.bm-view {
  height: 400px;
  width: 100%;
}
</style>
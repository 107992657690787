<template>
  <v-sheet
      class="px-xl-16 px-lg-16 px-md-16 px-sm-16 px-4 pt-xl-8 pt-lg-8 pt-md-8 pt-sm-8 pt-8 pb-xl-16 pb-lg-16 pb-md-16 pb-sm-16 pb-16">
    <v-row class="mx-xl-16 mx-lg-16 mx-md-0 mx-sm-0 mx-0" justify="center" dense>
      <v-col cols="10" v-for="(word, i) in words" :key="i">
        <div class="text-body-1">{{ word.paragraph }}</div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "AboutJoinUsShowJoinUs",
  data: () => ({
        words: [
          {paragraph: "在这里，我们有众多安全方面的高手，指点江山，高谈阔论；"},
          {paragraph: "在这里，我们是年轻的兄弟姐妹，我们亲如家人；"},
          {paragraph: "是每颗年轻拼搏的心，铸就了映涵的成功；"},
          {paragraph: "每个人都是发动机，每个人都能自我发光；"},
          {paragraph: "我们期待有激情有理想，充满正能量的朋友，加入我们的团队；"},
          {paragraph: "共同打造属于我们的移动互联网安全生态。"}
        ]
      }
  )
}
</script>

<style scoped>

</style>
<template>
  <v-sheet class="px-xl-16 px-lg-16 px-md-16 px-sm-16 px-4 py-xl-16 py-lg-16 py-md-16 py-sm-16 py-16">
    <v-row class="mx-xl-16 mx-lg-16 mx-md-0 mx-sm-0 mx-0" justify="center">
      <v-col xl="3" lg="3" md="4" sm="6" cols="12" v-for="(card, i) in cards" :key="i">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card class="pa-2 rounded-lg" height="100%" outlined :elevation="hover ? 12 : 0">
              <v-card-title>{{ card.title }}</v-card-title>
              <v-sheet min-height="120px">
                <v-card-text>{{ card.text }}</v-card-text>
              </v-sheet>
              <v-card-actions>
                <v-btn color="primary" v-if="card.href" :href="card.href">{{ card.btn }}</v-btn>
                <v-btn color="primary" v-if="card.to" :to="card.to">{{ card.btn }}</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "PurchaseApproach",
  data: () => ({
    cards: [
      {
        title: "项目咨询",
        text: "免费一对一项目与解决方案咨询服务，1个工作日内为您安排资深销售顾问。",
        btn: "立即发送邮件",
        // todo design a web page to send email via Formspree
        href: "Mailto:hezuo@inhamsh.com"
      },
      {
        title: "电话交流",
        text: "全国统一客户服务热线，若遇线路繁忙，请选择在线交谈进行产品和服务咨询。",
        btn: "立即拨打电话",
        href: "tel:021-31185093"
      },
    ]
  })
}
</script>

<style scoped>

</style>

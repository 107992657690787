<template>
  <v-parallax :src="img_src" :height="parallax_height">
    <v-overlay :absolute="true" :value="true">
      <v-row justify="center">
        <v-col class="pa-8" cols="12">
          <div
              class="text-xl-h2 text-lg-h2 text-md-h2 text-sm-h4 text-h4 text-center mb-xl-4 mb-lg-4 mb-md-4 mb-sm-2 mb-2"
              v-if="title">{{ title }}
          </div>
          <div
              class="text-xl-h4 text-lg-h4 text-md-h4 text-sm-h6 text-h6 text-center mb-xl-4 mb-lg-4 mb-md-4 mb-sm-2 mb-2"
              v-if="subtitle">{{ subtitle }}
          </div>
          <div class="text-subtitle-1 text-center mb-xl-4 mb-lg-4 mb-md-4 mb-sm-2 mb-2" v-if="description">
            {{ description }}
          </div>
        </v-col>
      </v-row>
    </v-overlay>
  </v-parallax>
</template>

<script>
export default {
  name: "BannerImage",
  props: {
    img_src: String,
    title: String,
    subtitle: String,
    description: String
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    parallax_height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 300
        case 'sm':
          return 300
        case 'md':
          return 400
        case 'lg':
          return 400
        case 'xl':
          return 400
      }
    }
  }
}
</script>

<style scoped>

</style>
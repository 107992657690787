<template>
  <v-main>
    <banner-image :img_src="banner.img_src" :title="banner.title" :subtitle="banner.subtitle"/>
    <about-contact-us-show-contact-us/>
  </v-main>
</template>

<script>
import BannerImage from "@/components/BannerImage";
import AboutContactUsShowContactUs from "@/components/AboutComponents/AboutContactUsShowContactUs";
export default {
  name: "AboutContactUs",
  components: {AboutContactUsShowContactUs, BannerImage},
  data: () => ({
    banner: {
      img_src: require('@/assets/homepage_about_parallax.png'),
      title: "关于我们",
      subtitle: "联系我们"
    }
  })
}
</script>

<style scoped>

</style>
<template>
  <v-main>
    <banner-image :img_src="banner.img_src" :title="banner.title" :description="banner.description"/>
    <partner-list/>
  </v-main>
</template>

<script>
import BannerImage from "@/components/BannerImage";
import PartnerList from "@/components/PartnerList";

export default {
  name: "Partner",
  components: {PartnerList, BannerImage},
  data: () => ({
    banner: {
      img_src: require('@/assets/homepage_about_parallax.png'),
      title: "合作伙伴",
      description: "他们都信赖映涵，携手共建安全生活"
    }
  })
}
</script>

<style scoped>

</style>

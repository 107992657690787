<template>
  <v-main>
    <banner-image :img_src="banner.img_src" :title="banner.title" :subtitle="banner.subtitle"/>
    <about-certification-show-certification/>
  </v-main>
</template>

<script>
import BannerImage from "@/components/BannerImage";
import AboutCertificationShowCertification from "@/components/AboutComponents/AboutCertificationShowCertification";

export default {
  name: "AboutCertification",
  components: {AboutCertificationShowCertification, BannerImage},
  data: () => ({
    banner: {
      img_src: require('@/assets/homepage_about_parallax.png'),
      title: "关于我们",
      subtitle: "荣誉资质"
    }
  })
}
</script>

<style scoped>

</style>
<template>
  <v-main>
    <banner-image :img_src="banner.img_src" :title="banner.title" :description="banner.description"/>
    <security-list/>
  </v-main>
</template>

<script>
import BannerImage from "@/components/BannerImage";
import SecurityList from "@/components/SecurityList";
export default {
  name: "Security",
  components: {SecurityList, BannerImage},
  data: () => ({
    banner: {
      img_src: require('@/assets/homepage_about_parallax.png'),
      title: "安全动态",
      description: "技术干货、行业时讯、专家解读"
    }
  })
}
</script>

<style scoped>

</style>